/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.toggle-menu').click(function (e) {
          e.preventDefault();
          $('.toggle-menu').toggleClass('menu-active');
          $('header nav').toggleClass('menu-open');
        });

        // smooth scroll to top
        $('#to-the-top').on('click', function (e) {
          e.preventDefault();
          $('body, html').animate({ scrollTop: 0 }, 600);
        });

        $('#scroll-down-button').on('click', function (e) {
          e.preventDefault();
          $('body, html').animate({ scrollTop: $("#banner-nav").offset().top }, 600);
        });

        var $videoContainer = $('#section-video');
        var navHeight = $('.sticky-nav').height();

        var videoHeight = function () {
          navHeight = $('.sticky-nav').height();
          $videoContainer.css({ 'height': $(window).outerHeight() - 70 });
        };

        if ($videoContainer.length > 0) {
          videoHeight();
        }

        $(window).on('resize', function () {
          if ($videoContainer.length > 0) {
            videoHeight();
          }
        });

        var navToggle = new Waypoint({
          element: document.getElementById('banner-nav'),
          handler: function (direction) {
            if (direction === 'down') {
              $('.sticky-nav').addClass('in-view');
            } else {
              $('.sticky-nav').removeClass('in-view');
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        function isIE() {
          var myNav = navigator.userAgent.toLowerCase();
          return (myNav.indexOf('msie') !== -1) ? parseInt(myNav.split('msie')[1]) : false;
        }

        window.isIEOld = isIE() && isIE() < 9;

        var video = document.getElementById('videoLoop');

        var img = $(video).data('placeholder'),
          mp4 = $(video).data('mp4'),
          webm = $(video).data('webm'),
          noVideo = $(video).data('src'),
          el = '';

        if ($(window).width() > 599 && !isIEOld) {
          el += '<video autoplay muted playsinline loop poster="' + img + '">';
          el += '<source src="' + mp4 + '" type="video/mp4">';
          el += '<source src="' + webm + '" type="video/webm">';
          el += '</video>';
        } else {
          el = '<div class="video-element" style="background-image: url(' + noVideo + ')"></div>';
        }

        $(video).prepend(el);

        // Navigation Handla
        $('.toggle-menu').click(function (e) {
          e.preventDefault();
          var scrollPosition = $(window).scrollTop();
          // console.log(scrollPosition);
          if (scrollPosition < 55 && $('.sticky-nav nav').hasClass('menu-open')) {
            $('body, html').animate({ scrollTop: $('html').offset().top + 50 }, 600);
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'food': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.food-gallery').flickity({
          wrapAround: true,
          prevNextButtons: false,
          imagesLoaded: true
        });

        $('.nav-tabs a').on('click', function(e) {
          e.preventDefault();
          $('.nav-tabs li').removeClass('active');
          $(this).parent().toggleClass('active');
          $('.tab-pane').removeClass('active');
          var target = $(this).attr('href');
          $(target).addClass('active in');
        });

        // Cheap hack to move the value column about the description
        $('#specials ul li').each(function () {
          $(this).children().first().insertAfter($(this).children().first().next());
        });
      }
    },
    'drinks': {
      init: function() {
        $('.nav-tabs a').on('click', function(e) {
          e.preventDefault();
          $('.nav-tabs li').removeClass('active');
          $(this).parent().toggleClass('active');
          $('.tab-pane').removeClass('active');
          var target = $(this).attr('href');
          $(target).addClass('active in');
        });
      }
    },
    'about': {
      init: function() {
        twitterFetcher.fetch({
          "profile": { "screenName": 'thechurchillvic' },
          "domId": '',
          "maxTweets": 2,
          "enableLinks": true,
          "showUser": false,
          "showInteraction": false,
          "showTime": false,
          "customCallback": function (tweets) {
            $('.twitter-feed').each(function () {
              $(this).prepend(tweets[$(this).data('tweet')]);
            });
          },
        });

        // Maps
        var $map = $('#google-map');

        if ($map.length > 0) {
          if (!(typeof window.google === 'object' && window.google.maps)) {
            console.error('Google Maps API is not loaded, cannot display map.');
          } else {
            // Make Map Go
            var mapStyle = [
              {
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#212121"
                  }
                ]
              },
              {
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#212121"
                  }
                ]
              },
              {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "featureType": "administrative.country",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              },
              {
                "featureType": "administrative.locality",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#bdbdbd"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#181818"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#1b1b1b"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#2c2c2c"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#8a8a8a"
                  }
                ]
              },
              {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#373737"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#3c3c3c"
                  }
                ]
              },
              {
                "featureType": "road.highway.controlled_access",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#4e4e4e"
                  }
                ]
              },
              {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
              },
              {
                "featureType": "transit",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#000000"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#3d3d3d"
                  }
                ]
              }
            ];

            var map = new window.google.maps.Map($map[0], {
              zoom: 17,
              center: new window.google.maps.LatLng(48.42549138699391, -123.36801052093507),
              draggable: !("ontouchend" in document),
              scrollwheel: false,
              fullscreenControl: false,
              mapTypeControl: false,
              rotateControl: false,
              streetViewControl: false,
              scaleControl: false,
              backgroundColor: '#000000',
              styles: mapStyle,
            });

            var marker = new window.google.maps.Marker({
              map: map,
              position: new window.google.maps.LatLng(48.425405, -123.367967),
              title: 'The Churchill',
            });

            // Size the map container
            var $mapboxContainer = $('.map-container');
            var $infoContainer = $('.info-container');

            var mapboxHeight = function () {
              if ( $(window).innerWidth() > 768 ) {
                $mapboxContainer.css({'height': $infoContainer.outerHeight()});
              } else {
                $mapboxContainer.css({'height': 260});
              }

              google.maps.event.trigger(map, 'resize');
            };

            mapboxHeight();

            $(window).on('resize', mapboxHeight);
          }
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
